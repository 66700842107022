// 封装 fetch 函数
async function customFetch(url, options = {}) {
  try {
    // 设置默认选项
    const defaultOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      ...options,
    };

    // 执行 fetch 请求
    const response = await fetch(url, defaultOptions);

    // 检查响应状态
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // 解析响应数据
    const data = await response.json();
    return data;
  } catch (error) {
    // 处理错误
    console.error("Fetch error:", error);
    throw error; // 重新抛出错误以便外部处理
  }
}

export default customFetch;
