<template>
  <div class="contact">
    <div class="banner">
      <!-- <img src="@/assets/partnership/incabin.png" alt="" /> -->
      <ImageWithPlaceholder src="/image/contact/contact.png" alt="Loaded Image">
        <!-- <template v-slot:placeholder>
          <img src="@/assets/incabin.png" alt="Placeholder" />
        </template> -->
      </ImageWithPlaceholder>
      <div class="overview-box">
        <div class="overview-item">
          <div class="overview-item-top">
            <span class="title">Marketing</span>
            <span class="icon">
              <img src="/image/contact/mail.svg" alt="" />
            </span>
          </div>
          <div class="email-address">marketing@arcsoft.com</div>
        </div>
        <div class="overview-item">
          <div class="overview-item-top">
            <span class="title">Job</span>
            <span class="icon">
              <img src="/image/contact/job.svg" alt="" />
            </span>
          </div>
          <div class="email-address">hr@arcsoft.com</div>
        </div>
      </div>
    </div>
    <div class="contact-box">
      <div class="content-wrapper">
        <h1-title :mb="40">Contact Us</h1-title>
        <div class="form-container">
          <form>
            <!-- Company -->
            <div class="form-group">
              <label for="company">Your Company <i>*</i></label>
              <input
                type="text"
                id="company"
                v-model="form.company"
                maxlength="200"
                minlength="2"
                :class="{ 'is-invalid': formErrors.company }"
                placeholder="Please type in"
              />
              <span v-if="formErrors.company" class="error">{{
                formErrors.company
              }}</span>
            </div>

            <!-- First Name and Last Name -->
            <div class="form-row">
              <div class="form-group">
                <label for="firstName">First Name <i>*</i></label>
                <input
                  type="text"
                  id="firstName"
                  v-model="form.firstName"
                  maxlength="50"
                  :class="{ 'is-invalid': formErrors.firstName }"
                  placeholder="Please type in"
                />
                <span v-if="formErrors.firstName" class="error">{{
                  formErrors.firstName
                }}</span>
              </div>

              <div class="form-group">
                <label for="lastName">Last Name <i>*</i></label>
                <input
                  type="text"
                  id="lastName"
                  v-model="form.lastName"
                  maxlength="50"
                  :class="{ 'is-invalid': formErrors.lastName }"
                  placeholder="Please type in"
                />
                <span v-if="formErrors.lastName" class="error">{{
                  formErrors.lastName
                }}</span>
              </div>
            </div>

            <!-- Email -->
            <div class="form-group">
              <label for="emailAddress">Email Address <i>*</i></label>
              <input
                type="email"
                id="email"
                v-model="form.emailAddress"
                :class="{ 'is-invalid': formErrors.emailAddress }"
                placeholder="Please type in"
              />
              <span v-if="formErrors.emailAddress" class="error">{{
                formErrors.emailAddress
              }}</span>
            </div>

            <!-- Region -->
            <div class="form-group">
              <label for="region">Region</label>
              <div class="custom-select">
                <select id="region" v-model="form.regionId">
                  <option
                    v-for="opt in regionList"
                    :key="opt.id"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <div class="custom-arrow"><span></span></div>
              </div>
            </div>

            <!-- Phone (with area code) -->
            <div class="form-group phone-group">
              <label for="phone">Phone</label>
              <div class="phone-row">
                <div class="custom-select">
                  <select v-model="form.areaCode">
                    <option v-for="opt in countryCode" :key="opt" :value="opt">
                      {{ opt }}
                    </option>
                  </select>
                  <div class="custom-arrow"><span></span></div>
                </div>
                <div class="line"></div>
                <input
                  type="text"
                  id="phone"
                  v-model="form.phone"
                  maxlength="40"
                  :class="{ 'is-invalid': formErrors.phone }"
                  placeholder="Please type in"
                />
              </div>
              <span v-if="formErrors.phone" class="error">{{
                formErrors.phone
              }}</span>
            </div>

            <!-- Comment -->
            <div class="form-group">
              <label for="comment">Comment</label>
              <textarea
                id="comment"
                v-model="form.comment"
                maxlength="2000"
                placeholder="Please type in"
              ></textarea>
            </div>

            <!-- Attachment -->
            <!-- <div class="form-group">
              <label for="file">Attachment</label>
              <input type="file" id="file" @change="handleFileUpload" />
              <span v-if="fileName" class="upload-status"
                >{{ fileName }} #uploaded status</span
              >
            </div> -->

            <p style="color: #a5a5a5">
              Fields marked with an asterisk (<i style="color: #e83021"> * </i>)
              must be completed.
            </p>

            <!-- Buttons -->
            <div class="form-actions">
              <button type="button" @click="handleReset" class="btn reset">
                Reset
              </button>
              <button type="button" class="btn submit" @click="handleSubmit()">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
// import countryCode from "@/utils/countryCode.js";
import customFetch from '@/utils/customFetch.js';
export default {
  name: 'Contact',
  metaInfo: {
    title: 'Privacy Policy',
    meta: [
      {
        name: 'Contact ArcSoft: Get in Touch with Us',
        content:
          'Reach out to ArcSoft for inquiries, support, and business opportunities. Whether you have questions about our products, need technical assistance, or are interested in partnerships, our team is here to help.',
      },
      {
        name: 'keywords',
        content:
          'Contact ArcSoft, ArcSoft support, business inquiries, technical assistance, partnership opportunities, contact information, customer service, reach out, email contact, phone contact, office locations', // 关键词
      },
    ],
  },
  components: { ImageWithPlaceholder },
  data() {
    return {
      countryCode: [],
      form: {
        company: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        areaCode: '+1',
        regionId: '1',
        phone: '',
        comment: '',
      },
      formErrors: {},
      fileName: null,
      regionList: [],
    };
  },
  mounted() {
    this.getDropList();
  },
  methods: {
    validateForm() {
      this.formErrors = {};

      if (!this.form.company || this.form.company.length < 2) {
        this.formErrors.company = 'Company name is required';
      }
      if (!this.form.firstName) {
        this.formErrors.firstName = 'First name is required';
      }
      if (!this.form.lastName) {
        this.formErrors.lastName = 'Last name is required';
      }
      if (!this.form.emailAddress) {
        this.formErrors.emailAddress = 'Email is required';
      } else if (!this.isValidEmail(this.form.emailAddress)) {
        this.formErrors.emailAddress = 'Invalid emailAddress format';
      }
      //   if (!this.form.phone) {
      //     this.formErrors.phone = "Phone number is required";
      //   }

      return Object.keys(this.formErrors).length === 0;
    },

    isValidEmail(emailAddress) {
      const re = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      return re.test(emailAddress);
    },
    getDropList() {
      customFetch('/requestBackend/api/queryMailParams', {}).then((res) => {
        if (res.success) {
          const { uuid, region, phone } = res.data;
          this.form.uuid = uuid;
          this.regionList = region || [];
          this.countryCode = phone || [];
        }
      });
    },
    handleSubmit() {
      if (this.validateForm()) {
        // alert("Form submitted successfully!");
        // // 可以处理表单数据，例如发送到服务器
        const regionName = this.regionList.find(
          (item) => item.id === this.form.regionId
        )?.name;
        const { areaCode, phone } = this.form;
        const params = {
          ...this.form,
          regionName,
          phone:
            areaCode && areaCode !== 'Others' ? `${areaCode} ${phone}` : phone,
        };
        // 使用示例
        customFetch('/requestBackend/api/submitForm', {
          method: 'POST',
          body: JSON.stringify(params),
        })
          .then((res) => {
            if (res.success) {
              this.$popup.open({
                content: 'Submission successful!',
                delay: 1500,
                type: 'success',
              });
              this.handleReset();
            } else {
              this.$popup.open({
                content: 'Submission failed.',
                delay: 1500,
                type: 'fail',
              });
            }
          })
          .catch(() => {
            this.$popup.open({
              content: 'Submission failed.',
              delay: 1500,
              type: 'fail',
            });
          })
          .finally(() => this.getDropList());
      }
    },
    handleReset() {
      this.form = {
        uuid: this.form.uuid,
        company: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        areaCode: '+1',
        regionId: '1',
        phone: '',
        comment: '',
        file: null,
      };
      this.formErrors = {}; // 重置校验错误信息
      this.fileName = null; // 清除文件名
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.file = file;
        this.fileName = file.name;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
  .overview-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    .overview-item {
      box-sizing: border-box;
      padding: 24px;
      width: 400px;
      height: 120px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      border: solid 1px #d9d9d9;
      //   box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
      &:last-child {
        border-left: none;
      }
      .overview-item-top {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 28px;
        }
        .icon {
          padding: 5px 2px;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      .email-address {
        font-size: 16px;
      }
    }
  }
  img {
    width: 100%;
  }
}

.contact-box {
  margin: 140px 0 50px 0;
  // h1 {
  //   text-align: center;
  // }
  .contact-text {
    margin-bottom: 24px;
  }
}

.content-wrapper {
  width: 480px;
  margin: 0 auto;
}

/* 容器布局 */
.form-container {
  max-width: 600px;
  margin: 0 auto;
  // padding: 0 20px;
}

/* 每个表单组的样式 */
.form-group {
  box-sizing: border-box;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  label {
    line-height: 24px;
    color: #a5a5a5;
    display: block;
    padding: 9px 0 8px 0;
    font-size: 14px;
  }
  label i {
    color: #e83021;
  }
}

/* 表单行，保证 First Name 和 Last Name 在同一行 */
.form-row {
  display: flex;
  justify-content: space-between;
  color: #222222;
}

/* 两列输入框 */
.form-row .form-group {
  width: 48%;
}

/* 电话区号和输入框排版 */
.phone-row {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: #f8f8f8;
  .line {
    width: 1px;
    height: 20px;
    background-color: #ccc;
  }
}

.phone-row select {
  width: 90px;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.phone-row input {
  width: 80%;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

/* 输入框样式 */
input,
select,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  background-color: #f8f8f8;
}

input::placeholder,
textarea::placeholder {
  color: #a5a5a5;
}

.custom-select {
  position: relative;
}

.custom-select select {
  padding-right: 30px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Custom arrow styling */
.custom-select .custom-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  pointer-events: none; /* Prevent mouse events from this element */
  transform: translateY(-50%);
  padding: 5px 3px;
  display: flex;
  > span {
    display: inline-block;
    border-top: 2px solid #222222;
    border-right: 2px solid #222222;
    width: 5px;
    height: 5px;
    transform: rotate(135deg);
  }
}

/* 提示信息 */
input.is-invalid {
  border-color: red;
}

textarea {
  resize: vertical;
  height: 80px;
}

/* 错误信息 */
.error {
  display: none;
  color: red;
  font-size: 12px;
}

/* 文件上传状态 */
.upload-status {
  margin-top: 5px;
  font-size: 12px;
  color: #555;
}

/* 按钮样式 */
.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.btn {
  padding: 8px 22px 8px 22px;
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
}

.reset {
  background-color: #fafafa;
  border: solid 1px #d9d9d9;
  color: #222222;
  margin-right: 10px;
}

.submit {
  background-color: #e83021;
  color: #fff;
}

.btn:focus {
  outline: none;
}

/* 按钮的 hover 效果 */
.reset:hover {
  background-color: #e0e0e0;
}

.submit:hover {
  background-color: #ff6666;
}
</style>
